// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIEendpoint :"https://localhost:8080",
  pdfUrl:"https://wellcareltd.co.uk/documents/application.pdf",
  timesheetUrl:"https://wellcareltd.co.uk/documents/wellcaretimesheet.pdf",
};


