import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NoAuthGuard } from '../app/core/guards/no-auth-guard'


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [NoAuthGuard], // Should be replaced with actual auth guard
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('../app/modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'about',
        loadChildren: () =>
          import('../app/modules/aboutus/aboutus.module').then(m => m.AboutusModule)
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('../app/modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'services',
        loadChildren: () =>
          import('../app/modules/services/services.module').then(m => m.ServicesModule)
      }
    ]
  },

  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
