<div>
    <div class="app-background">
        <app-header></app-header>

        <div>
            <router-outlet></router-outlet>
        </div>
        <div class="footer-postion">
            <app-footer></app-footer>
        </div>

    </div>
</div>